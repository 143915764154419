






































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import ReportService from '@/modules/report/ReportService'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import { GetReport_GetReport, GetReport_GetReport_statuses_items } from '@/generated/GetReport'
import StatusMap from "@/modules/shipments/views/detail/components/StatusMap.vue";
import BaseDateTimePicker from "@/general/components/general/BaseDateTimePicker.vue";
import BaseTextField from "@/general/components/general/BaseTextField.vue";
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";
import DownloadMixin from '@/general/mixins/DownloadMixin'
import {DocumentType} from "@/generated/globalTypes";
import ECMRService from '../ecmrs/ECMRService'

@Component({
  components: {
    BaseDataTable,
    BaseTextField,
    BaseDateTimePicker,
    StatusMap,
    InfoCard,
  },
})
export default class Report extends Mixins(ReportService, DownloadMixin, ECMRService) {
  from = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30)
  to = new Date()
  reportObject: GetReport_GetReport | null
  report: Array<Array<any>> = []
  search = ''
  statuses: GetReport_GetReport_statuses_items[] = []

  center: string[] = ['52.373882', '4.891711']

  created() {
    this.getR()
  }

  get headers() {
    return [new Header('Metric', 'metric', false), new Header('Amount', 'amount', false)]
  }

  ecmrReport() {
    this.getECMRReport({ itemsPerPage: 0, page: 0, period: { from: this.from.getTime(), to: this.to.getTime() } }).then(result => {
      let text = 'Identifier,Company,Date'
      const format: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }
      result.forEach(i => {
        text += `\n${i.identifier},${i.company},${new Date(i.created).toLocaleString('en-GB', format)}`
      })
      this.downloadFile({ mime: 'application/csv', name: `ECMRReport_${this.periodString()}.csv`, base64: btoa(text), id: 0, type: DocumentType.DOCUMENT, __typename: 'DocumentModel', meta: null })})
  }

  ecmrStats() {
    let text = 'Company,Amount'
    this.reportObject?.ecmr?.items?.forEach((item) => {
      text += `\n${item.key},${item.value}`
    })
    this.downloadFile({ mime: 'application/csv', name: `ECMRStats_${this.periodString()}.csv`, base64: btoa(text), id: 0, type: DocumentType.DOCUMENT, __typename: 'DocumentModel', meta: null })
  }

  private periodString() {
    const format: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }

    return new Date(this.from.getTime()).toLocaleString('en-GB', format) + '- ' + new Date(this.to.getTime()).toLocaleString('en-GB', format)
  }

  getR() {
    this.from.setHours(0)
    this.to.setHours(23)
    this.getReport({ page: 0, itemsPerPage: 20, search: this.search, period: { from: this.from.getTime(), to: this.to.getTime() } }).then((report) => {
      this.reportObject = report
      this.report = [
        ['Files imported', report.importedConversionsCount],
        ['Plannings in progress', report.planning.unfinished],
        ['Finished plannings', report.planning.finished],
        ['Shipments in progress', report.shipment.unfinished],
        ['Delivered shipments', report.shipment.finished],
        ['Access codes shared via SMS', report.smsCount],
        ['Access codes shared via Email', report.emailCount],
        ['Users created', report.users],
        ['e-CMRs created', report.ecmr?.items?.reduce((accumulator, current) => accumulator + current.value, 0) ?? 0],
      ]
      this.statuses = report.statuses.items.filter(item => item.location?.includes(',')).sort(item => item.created)
      if (this.statuses.length) {
        this.center = this.statuses[this.statuses.length - 1].location!.split(',')
      }
    })
  }
}
