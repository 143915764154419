import {Component, Vue} from "vue-property-decorator";
import gql from "graphql-tag";
import {ApolloQueryResult} from "apollo-client";
import { Pagination } from '@/generated/globalTypes'
import { GetReport, GetReport_GetReport } from "@/generated/GetReport";
import {STATUS_MODEL_FRAGMENT} from "@/modules/graphql/StatusModelFragment";

@Component
export default class ReportService extends Vue {

  private getReportQuery = gql`
  ${STATUS_MODEL_FRAGMENT}

  query GetReport($pagination: Pagination!) {
    GetReport(a0: $pagination) {
      users,
      planning {
        unfinished,
        finished
      },
      shipment {
        unfinished,
        finished
      },
      ecmr {
        items {
          key
          value
        }
      },
      statuses {
        items {
          ...StatusModelFields
        }
      }
      smsCount
      emailCount
      importedConversionsCount
    }
  }
  `

  getReport(pagination: Pagination): Promise<GetReport_GetReport> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .query({
            query: this.getReportQuery,
            variables: {
              pagination: pagination
            }
          })
          .then((result: ApolloQueryResult<GetReport>) => {
            resolve(result.data.GetReport)
          })
          .catch(reason => {
            reject(reason)
          })
    })
  }
}
